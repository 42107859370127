import React from 'react';
import Button from "./Button";
import { StaticImage } from "gatsby-plugin-image"
import Carbonbadge from "react-carbonbadge"

const ValueProp = props => {
    
  return (
    <section className="value-prop">
      <div className="value-prop-content">
        <div className="main-message">
          <p className="main-abovetitle">~ Développons vos solutions ensemble</p>
          <Carbonbadge darkMode={true}/>
          <h1>Nous développons vos applications web & mobiles</h1>
          <p className="main-subtitle">L'équipe de T-Labs utilise les dernières technologies pour développer éfficacement des solutions web & mobiles.</p>
          <Button label="Contactez-nous" />
        </div>
        <div className="main-photo">
          <StaticImage src="../assets/tlabs2.png" alt="TLabs"  placeholder ="blurred"/>
        </div>
      </div>
    </section>
  )
};

export default ValueProp;
