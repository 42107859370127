import React from 'react';
import Button from "./Button";

const CallToAction = props => {

  return (
    <section className="cta" id="CTA">
      <div className="cta-1">
        <h3>Adresse</h3>
        <p>ToolPad <br/>
          MAME, 49 Boulevard Preuilly,<br/>
          Bureau 162<br/>
          37000 Tours<br/>
        </p>
      </div>
      <div className="cta-2">
        <h3>Contact</h3>
        <p>Email : adrian.guery@toolpad.fr</p>
        <p>Téléphone : 06 02 60 47 38</p>
      </div>
    </section>
  )
};

export default CallToAction;
