import React from 'react';
import { StaticImage } from "gatsby-plugin-image"
import '../styles/components/_social-proof.scss';

const SocialProof = props => {

  return (
    <section className="social-proof">
    <h2>Ils nous ont fait confiance</h2>

      <div class="div-block">
        <div class="w-layout-grid grid">
              <StaticImage className="image-social-proof" src="../assets/social-proof/bpi.png" alt="BPI France" objectFit="contain" placeholder ="blurred"/>
              <StaticImage className="image-social-proof" src="../assets/social-proof/cdd.png" alt="Caisse des dépots" objectFit="contain" placeholder ="blurred"/>
              <StaticImage className="image-social-proof" src="../assets/social-proof/cvl.png" alt="Centre Val de Loire" objectFit="contain" placeholder ="blurred"/>
              <StaticImage className="image-social-proof" src="../assets/social-proof/la-french-tech.png" alt="La French Tech" objectFit="contain" placeholder ="blurred"/>
              <StaticImage className="image-social-proof" src="../assets/social-proof/la-poste.png" alt="La Poste" objectFit="contain" placeholder ="blurred"/>
              <StaticImage className="image-social-proof" src="../assets/social-proof/ligue-enseignement.png" alt="Ligue de l'enseignement" objectFit="contain" placeholder ="blurred"/>
              <StaticImage className="image-social-proof" src="../assets/social-proof/pepite.png" alt="PEPITE" objectFit="contain" placeholder ="blurred"/>
              <StaticImage className="image-social-proof" src="../assets/social-proof/tours.png" alt="Ville de Tours" objectFit="contain" placeholder ="blurred"/>
              <StaticImage className="image-social-proof" src="../assets/social-proof/tours-loire-valley.png" alt="Tours Loire Valley" objectFit="contain" placeholder ="blurred"/>
              <StaticImage className="image-social-proof" src="../assets/social-proof/vivalab.png" alt="Vivalab" objectFit="contain" placeholder ="blurred"/>
        </div>
      </div>

      {/* 

      <div className="social-proof-icons">
        <StaticImage src="../assets/social-proof/bpi.png" alt="BPI France" objectFit="contain" placeholder ="blurred"/>
        <StaticImage src="../assets/social-proof/cdd.png" alt="Caisse des dépots" objectFit="contain" placeholder ="blurred"/>
        <StaticImage src="../assets/social-proof/cvl.png" alt="Centre Val de Loire" objectFit="contain" placeholder ="blurred"/>
        <StaticImage src="../assets/social-proof/la-french-tech.png" alt="La French Tech" objectFit="contain" placeholder ="blurred"/>
        <StaticImage src="../assets/social-proof/la-poste.png" alt="La Poste" objectFit="contain" placeholder ="blurred"/>
        <StaticImage src="../assets/social-proof/ligue-enseignement.png" alt="Ligue de l'enseignement" objectFit="contain" placeholder ="blurred"/>
        <StaticImage src="../assets/social-proof/pepite.png" alt="PEPITE" objectFit="contain" placeholder ="blurred"/>
        <StaticImage src="../assets/social-proof/tours.png" alt="Ville de Tours" objectFit="contain" placeholder ="blurred"/>
        <StaticImage src="../assets/social-proof/tours-loire-valley.png" alt="Tours Loire Valley" objectFit="contain" placeholder ="blurred"/>
        <StaticImage src="../assets/social-proof/vivalab.png" alt="Vivalab" objectFit="contain" placeholder ="blurred"/>
      </div>
      */}
    </section>
  )
};

export default SocialProof;
