import React from 'react';
import { StaticImage } from "gatsby-plugin-image"

const Testimonials = props => {

  return (
    <section className="testimonials">
      <h2>Notre équipe</h2>
      <h3>Une squad de 3 ingénieurs en informatique</h3>
      <div className="testimonial-group">
        <div className="testimonial-card-1">
          <div className="testimonial-1-div-photo">
            <StaticImage src="../assets/testimonials/alexandre-fauchard.png" alt="Alexandre Fauchard" className="testimonial-1-photo" placeholder ="blurred"/>
          </div>
          <div className="testimonial-1-message">
            <h3 className="testimonial-1-name">Alexandre Fauchard</h3>
            <p className="testimonial-1-title">Développeur FullStack, Ingénieur logiciel & Co-fondateur</p>
            <a className="testimonial-link" href="https://www.malt.fr/profile/adrianguery1">Profil sur Malt &#8594; </a>
          </div>
        </div>
        <div className="testimonial-card-2">
          <div className="testimonial-2-div-photo">
            <StaticImage src="../assets/testimonials/adrian-guery.png" alt="Adrian Guery" className="testimonial-2-photo" placeholder ="blurred"/>
          </div>
          <div className="testimonial-2-message">
            <h3 className="testimonial-2-name">Adrian Guery</h3>
            <p className="testimonial-2-title">Product owner, AMOA ,développeur front-end & Co-fondateur</p>
            <a className="testimonial-link" href="https://www.malt.fr/profile/alexandrefauchard">Profil sur Malt &#8594; </a>
          </div>
        </div>
      <div className="testimonial-card-3">
          <div className="testimonial-3-div-photo">
            <StaticImage src="../assets/testimonials/justin-martin.png" alt="Justin Martin" className="testimonial-3-photo" placeholder ="blurred"/>
          </div>
        <div className="testimonial-3-message">
          <h3 className="testimonial-3-name">Justin Martin</h3>
          <p className="testimonial-3-title">Développeur FullStack, Ingénieur DevOps & Co-fondateur</p>
            <a className="testimonial-link" href="https://www.malt.fr/profile/justinmartin1">Profil sur Malt &#8594; </a>
        </div>
        </div>
      </div>
    </section>
  )
};

export default Testimonials;