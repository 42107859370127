import React from 'react';
import { StaticImage } from "gatsby-plugin-image"
import { Link } from 'gatsby';
import BorderButton from "./BorderButton";

const ProductBenefits = props => {

  return (
    <section className="product-benefits">
      <div className="product-benefits-group">
        <div className="product-benefit-1-tagline">
          <h3>Développement d'applications web (SaaS)</h3>
          <p>TLabs est spécialisé dans le développement de SaaS. Nous utilisons les dernières technologies pour produire en quelques semaines une première version qui évoluera avec la solution de manière optimisée.</p>
          <Link to="/portfolio"><BorderButton label="En savoir plus &#8594;"></BorderButton></Link>
        </div>
        <div className="product-benefit-1-image">
          <StaticImage src="../assets/product-benefits/saas-t-labs.png" alt="SaaS TLabs" className="testimonial-1-photo" placeholder ="blurred"/>
        </div>
        <div className="product-benefit-2-tagline">
          <h3>Développement d'applications mobile (IOS & Android)</h3>
          <p>Vous souhaitez réaliser ou maintenir une application mobile ? TLabs est en mesure de vous accompagner dans le développement de votre application mobile (IOS et Android).</p>
          <Link to="/portfolio"><BorderButton label="En savoir plus &#8594;"></BorderButton></Link>
        </div>
        <div className="product-benefit-2-image">
          <StaticImage src="../assets/product-benefits/mobile-t-labs.png" alt="SaaS TLabs" objectFit="contain" className="testimonial-1-photo" placeholder ="blurred"/>
        </div>
        <div className="product-benefit-3-tagline">
          <h3>Gestion de projets, Product owner (SCRUM)</h3>
          <p>ToolPad est spécialisé dans le développement de SaaS. Nous utilisons les dernières technologies pour produire en quelques semaines une première version qui évoluera avec la solution de manière optimisée.</p>
          <Link to="/portfolio"><BorderButton label="En savoir plus &#8594;"></BorderButton></Link>
        </div>
        <div className="product-benefit-3-image"></div>
        <div className="product-benefit-4-tagline">
          <h3>DevOps & cloud architect</h3>
          <p>ToolPad est spécialisé dans le développement de SaaS. Nous utilisons les dernières technologies pour produire en quelques semaines une première version qui évoluera avec la solution de manière optimisée.</p>
          <Link to="/portfolio"><BorderButton label="En savoir plus &#8594;"></BorderButton></Link>
        </div>
        <div className="product-benefit-4-image"></div>
      </div>
    </section>
  )
};

export default ProductBenefits;
